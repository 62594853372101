.oms {
  background: #2c3436;
}

.docket::-webkit-scrollbar {
  display: none;
}

.docket-border-table-service {
  border-color: #0042df !important;
}

.docket-background-table-service {
  background-color: #0042df !important;
}

.docket-border-counter-service {
  border-color: #b119b6 !important;
}

.docket-background-counter-service {
  background-color: #b119b6 !important;
}

.docket-border-takeaway-delivery {
  border-color: #7117ae !important;
}

.docket-background-takeaway-delivery {
  background-color: #7117ae !important;
}

.docket-border-takeaway-pickup {
  border-color: #b771e6 !important;
}

.docket-background-takeaway-pickup {
  background-color: #b771e6 !important;
}

:root {
  --docket-indicator-default: #bdc4d1;
  --docket-indicator-red: #ffa394;
  --docket-indicator-green: #879e15;
  --docket-indicator-blue: #0042df;
}

.docket {
  padding: 2px 0px;
  display: flex;
  flex-wrap: wrap;

  .docketItem {
    background: white;
    margin: 0;
    flex-direction: column;
    display: flex;
    position: relative;
    height: calc(100vh / 2 - 65px);
    min-height: 380px;

    .card-header {
      padding: 0.3rem 0.5rem;
    }
  }
}

.docket-indicator-badge {
  background-color: var(--white);
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: normal;
}

.oms-selector.card-default {
  background-color: #555e65;
  border-color: #555e65;
  color: #f5f5f5;

  .rdtPicker {
    border-color: #555e65;
    background-color: #2c3436;

    td.rdtDay:hover,
    thead tr:first-child th:hover,
    thead tr:first-child th:hover,
    td.rdtMonth:hover,
    td.rdtYear:hover {
      background-color: #555e65;
    }
  }
}

.oms-selector .maestro-date button {
  padding: 7px;
}

.maestro-date {
  width: 100%;
  float: left;
}

.maestro-date .rdt {
  padding: 0;
}

.maestro-date input {
  text-align: center;
}

.rdt.rdtOpen .rdtPicker {
  top: 34px;
}

.docketHeader {
  display: flex;
  justify-content: space-between;

  .tableNo {
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-transform: capitalize;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;

    &.wrap {
      flex-wrap: wrap;
    }
  }
}

.timer-container {
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding: 6px;
  background: #4d4d4d;
  color: white;
  align-items: center;
}

.takeawayTimer {
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding: 6px;
  background: #e3e1e1;
  color: black;
  align-items: center;
}

.icon {
  width: 20;
  height: 20;
}

.docketText {
  color: 'white';
  text-align: 'center';
}

.flexbox-container {
  display: flex;
  flex-flow: column wrap;
  height: 500px;
}

.flexbox-container.wrap {
  flex-wrap: wrap;
}

.flexbox-container.column {
  flex-direction: column;
}

.docketList {
  display: flex;
  flex-direction: column;
  flex-flow: wrap column;
  overflow-x: hidden;
  overflow-y: auto;
  // height: calc(100vh / 2 - 120px);

  .course-group-header {
    font-size: 14px;
    background: #000000;
    text-align: center;
    color: white;
    max-width: 100%;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    text-wrap: nowrap;
  }
  .name {
    font-size: 14px;
    background: #bdc4d1;
    text-align: center;
    text-transform: uppercase;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.summaryList {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-flow: wrap column;
  flex-flow: wrap column;
  //overflow: hidden;
  background: white;

  width: 16%;
  // height: calc(100vh / 2 - 120px);

  .category {
    font-size: medium;
    background: #4d4d4d;
    color: #ffff;
    padding: 6px;
  }
  .name {
    font-size: 16px;
    background: #bdc4d1;
    text-align: center;
    padding: 6px;
    text-transform: uppercase;
  }
}

//docket DocketHeaderBar
.docketHeaderBar {
  width: 100%;
  background: #073e59;
  height: 40px;
}

.docket-product {
  display: flex;
  line-height: 1.4em;

  // min-width: calc(100vw / 6);
  // min-width: calc(100vw /6 -96px);
  .quantity {
    font-size: 16px;
    padding-left: 4px;
    padding-right: 6px;
    min-width: 30px;
  }

  .product {
    flex: 1;

    .product-name {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      text-wrap: balance;
    }

    .product-additions {
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      color: #4d4d4d;

      &_list {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
    }

    .items {
      display: flex;
    }
  }

  .duration {
    margin-right: 8px;
  }
}

@media (max-width: 475px) {
  .docket {
    .docketItem {
      flex: 100%;
      height: auto;
    }
  }
  .docket-product {
    width: 100vw;
  }
}

.DockerFooter {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #2c3436;

  .footer-filter {
    display: inline-block;

    .dropdown {
      width: 150px;
    }
  }
}

.takeaway-btn {
  height: 44px;
  font-size: 18px;
  color: #fff;
  background: #6d800e;
  border: 0px;
}

.oms-docket-loading {
  background: #8d5dec73;
  /* width: 300px; */
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  /* width: auto; */
  /* flex-direction: column; */
  height: calc(100vh / 2 - 65px);
  /* flex-flow: wrap column; */
  /* overflow: hidden; */
  min-width: 16.6667%;
}
